import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Nav, Navbar } from "react-bootstrap";
import Sports from "../assets/images/games-icon/headicon/sports.png";
import Exchange from "../assets/images/games-icon/headicon/exchange.png";
import Sportsbook from "../assets/images/games-icon/headicon/sportsbook.png";
import Evolution from "../assets/images/games-icon/headicon/evolution.png";
import Ezugi from "../assets/images/games-icon/headicon/ezugi.png";
import Slots from "../assets/images/games-icon/headicon/slots.png";
import Kingmaker from "../assets/images/games-icon/headicon/kingmaker.png";
import Aviator from "../assets/images/games-icon/Aviator.png";

import Menu from "../assets/images/icons/toggle-icon.png";
import WhatsappIconNew from "../assets/images/social/whatsapp.png";
import TelegramIconNew from "../assets/images/social/telegram.png";
import GamesSearchModal from "../views/components/GamesSearchModal";

const Header = () => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const htmlElement = document.documentElement;

  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (realTimeValue) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };

  return (
    <header className="header beforeheader">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="leftbar_toggle d-md-none">
                  <Button variant="primary" onClick={() => handleToggleClick()}>
                    <img src={Menu} />
                  </Button>
                </div>
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>

                <GamesSearchModal />
              </div>

              <div className="headerRight">
                <ul className="social d-none d-sm-flex">
                  <li>
                    <a
                      href={
                        appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                      }
                      target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                    >
                      {/* <TelegramIcon /> */}
                      <img src={TelegramIconNew} />
                    </a>
                  </li>
                  <li>
                    <a
                      className="panel"
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      {/* <WhatsappIcon /> */}
                      <img src={WhatsappIconNew} />
                    </a>
                  </li>
                </ul>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/sign-in");
                  }}
                  className="button-primary btn_signin"
                >
                  SIGN IN
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate("/mobile-number");
                  }}
                  className="button-primary btn_signup btn-secondary ms-2"
                >
                  SIGN UP
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div xs={12} className="bottom_head">
        <Navbar expand="md">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/sign-in">
                  <img src={Sports} />
                  Sports
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Exchange} />
                  Exchange
                </Nav.Link>
                <Nav.Link href="/sign-in" className="">
                  <img src={Sportsbook} />
                  Sportsbook
                </Nav.Link>
                <Nav.Link href="/sportsbook2" className="">
                  <img src={Sportsbook} />
                  Sportsbook 2
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Evolution} />
                  Evolution
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Ezugi} />
                  Ezugi
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Slots} />
                  Slots
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Kingmaker} />
                  Kingmaker
                </Nav.Link>
                <Nav.Link href="/sign-in">
                  <img src={Aviator} />
                  Aviaitor
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
